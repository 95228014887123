

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../public/fonts/proximanova/proximanova_black.ttf') format('opentype'); /* Adjust the format as per your font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova regular';
  src: url('../public/fonts/proximanova/proximanova_regular.ttf') format('opentype'); /* Adjust the format as per your font file */
  font-weight: normal;
  font-style: normal;
}
/*
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}
*/


body {
  margin: 0;
  font-family: -apple-system, 'Proxima Nova', 'Proxima Nova regular', 'Proxima Nova regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-name="fullscreen"] {
    display: none;
}

div.w-md-editor-toolbar > ul:nth-child(2) > li.w-md-editor-toolbar-divider {
    display: none;
} 